import { css } from '@emotion/react';
import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import Article from '../../components/Article';
import { SecondaryBtn } from '../../components/Buttons/Secondary';
import CommonHero from '../../components/layout/CommonHero';
import Layout from '../../components/layout/Layout';
import Seo from '../../components/layout/Seo';

export default function AdultBraces() {
    return (
        <Layout>
            <Seo
                title="adult braces"
                description="Welcome to Land Orthodontics. Orthodontist in St Croix, VI. Premier orthodontic specialists for children, teens, and adults. Affordable braces."
            />
            <CommonHero title="adult braces" />
            <Article>
                <h1>Adult Braces</h1>
                <p>
                    Who says braces are just for kids? In fact, adult orthodontic treatment is up
                    40% from just two decades ago. It's never too late to get serious about your
                    oral health and work towards the smile you've always dreamed of! If you've gone
                    years without showing your smile, we are pleased to tell you that you are just
                    as good of a candidate for braces as your 12-year-old daughter. And we treat
                    full families of patients all the time - we've even treated 3 generations at
                    once, so contact us today to set up your own free consultation. We would love
                    nothing more than to help give you your perfect smile!
                </p>

                <article>
                    <StaticImage
                        src="../../images/services/adults/01.jpg"
                        alt="adult braces"
                        loading="eager"
                        width={780}
                        height={780}
                        className="gatsby-img-box"
                    />
                    <div className="textBox">
                        <h2>invisalign</h2>
                        <p>
                            Clear aligners such as Invisalign are a fantastic option if you're
                            really trying to fly under the radar with your braces. Until you get up
                            close and personal, you won't even know you're straightening your teeth
                            at all. Using a series of clear aligner trays, we gradually shift and
                            move your teeth into ideal alignment. It's amazing what this innovative
                            technology can do!
                        </p>
                        <SecondaryBtn
                            as={Link}
                            to="/invisalign/"
                            css={css`
                                margin-top: 32px;
                            `}
                        >
                            LEARN MORE
                        </SecondaryBtn>
                    </div>
                </article>

                <article>
                    <div className="textBox">
                        <h2>CLEAR BRACKETS</h2>
                        <p>
                            We understand that as an adult, you might desire a little more
                            discretion than the 13-year-old junior cheerleader in the chair beside
                            you. We offer clear ceramic brackets for our adult patients to minimize
                            the "metal-mouth" look that has so long characterized life in braces.
                            And no need to worry about alternating your colors with the holidays.
                            We've got you covered with clear o-rings and rubber bands, too.
                        </p>
                    </div>

                    <StaticImage
                        src="../../images/services/adults/02.jpg"
                        alt="adult braces"
                        width={780}
                        height={780}
                        className="gatsby-img-box"
                    />
                </article>
            </Article>
        </Layout>
    );
}
